import {Component, effect, Input} from '@angular/core';
import {QuestionService} from "../../services/question.service";
import {WhitelabelService} from "../../services/whitelabel.service";
import MarkdownIt from 'markdown-it';
import DOMPurify from "dompurify";
import {ChatMessage} from "../../interfaces/chat_message";
import {ChatRole} from "../../interfaces/ChatRole";
import {last} from "rxjs";


@Component({
  selector: 'answer-display',
  templateUrl: 'answer-display.component.html',
  styleUrls: ['answer-display.component.scss']
})
export class AnswerDisplayComponent {

  language = localStorage.getItem("userLanguage")
  iconSource = this.whitelabelService.logoUrl
  displayProgressBar: boolean = true;

  protected readonly ChatRole = ChatRole;


  promptRequest: ChatMessage | undefined = undefined

  latestResponseMeta: { [p: string]: any }= {
    client_request_uuid: "",
    server_request_uuid: "",
    document_references: [],
    search_only: false,
    is_completed: false,
    limit_reached: false
  };

  latestResponse: ChatMessage={
    content: "",
    role: ChatRole.ASSISTANT,
    meta: this.latestResponseMeta
  }

  chatHistory: ChatMessage[] = []

  responseCache = ""

  latestResponseDocumentReferences = []
  searchOnly = false
  easyLanguage = false
  isCompleted = false

  @Input() rating: any;
  @Input() setRating!: (newRating : number) => void;

  displayedColumns: string[] = ['entry'];
  sourcesTableHeader: string = "";

  expanded = true;

  constructor(
    private questionService : QuestionService,
    private whitelabelService: WhitelabelService
    ) {
    effect(() =>{ //update signals from questionService when their values change
      this.promptRequest = this.questionService.promptRequestSignal();
    })
    effect(() =>{ //update signals from questionService when their values change
      this.displayProgressBar = this.questionService.displayProgressBar();
    })
    effect(() =>{ //update signals from questionService when their values change
      this.chatHistory = this.processServerResponse(this.questionService.chatHistorySignal());
      this.sourcesTableHeader = this.searchOnly ? $localize`Suchresultate` : $localize`Quellen`
    })
  }

  processServerResponse(chatHistory: ChatMessage[]): ChatMessage[] {

    // sort chunks into the chats they belong to & parse into HTML
    chatHistory = this.sortChatHistory(chatHistory)


    if (chatHistory.length > 1) {
        this.latestResponse = chatHistory[(chatHistory.length - 1)]
    }
    this.latestResponseMeta = this.latestResponse.meta
    this.searchOnly =  this.latestResponse.meta["search_only"]
    this.isCompleted = this.latestResponse.meta["is_completed"]
    this.easyLanguage = this.promptRequest?.meta["easy_language"]


    this.latestResponseDocumentReferences = this.latestResponse.meta["document_references"]

    let documentReference : any;
    if (this.latestResponseDocumentReferences){
      for (documentReference of this.latestResponseDocumentReferences) {

      // cleanup white spaces
      if (documentReference.title === " ") documentReference.title = ""
      if (documentReference.subject === " ") documentReference.subject = ""
      if (documentReference.filename === " ") documentReference.filename = ""

      // format date
      if (documentReference.date) {
        documentReference.date = new Date(documentReference.date).toLocaleDateString()
      }

      if (documentReference.link && !documentReference.filename) {
        let lastPathComponent =  new URL(documentReference.link).pathname.split("/").pop();
        if (lastPathComponent != null) {
          documentReference.filename = lastPathComponent;
        }
      }

      documentReference.filename = decodeURIComponent(documentReference.filename)
      let filenameReadabilityScore = this.readabilityScore(documentReference.filename)

      let titleToDisplayReadabilityScore = this.readabilityScore(documentReference.title_to_display)

      if (!documentReference.title_to_display || titleToDisplayReadabilityScore < 0.5) {
        if (documentReference.filename && filenameReadabilityScore > 0.5) {
          documentReference.title_to_display = documentReference.filename;
        } else if (documentReference.title && documentReference.subject) {
          documentReference.title_to_display = `${documentReference.subject} - ${documentReference.title}`;
        } else if (documentReference.title) {
          documentReference.title_to_display = documentReference.title;
        } else if (documentReference.subject) {
          documentReference.title_to_display = documentReference.subject;
        } else if (documentReference.filename) {
          documentReference.title_to_display = documentReference.filename;
        }
      }

      this.whitelabelService.setTitleToDisplayForWhitelabel(documentReference)
    }

    }
    return chatHistory
  }

  private readabilityScore(text: string) {
    if (!text) return 0

    const regex = /[a-zA-Z]/g;
    let match = text.match(regex)
    let numberOfCharacters = match ? match.length : 0
    let score = text.length < 8 ? 0 : 1 / text.length * numberOfCharacters
    return score
  }

  parseResponse(answer: string) {

    // convert to html automatically and sanitize that html
    const md = MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
    })

    let sanitizedHtml = DOMPurify.sanitize(md.render(answer));

    //find and replace all occurrences of phone numbers, unlike links and email addresses, they are not recognized by marked.js
    let phone_regex = /\s(\+?(\d|\d\s){10,11})/gi
    sanitizedHtml = sanitizedHtml.replaceAll(phone_regex, " <a target=\"_blank\" href=\"tel:$1\">$1</a>")

    //find all occurrences of links and replace them with links that open in a new tab
    sanitizedHtml = sanitizedHtml.replaceAll(/(<a href=".*?")/g, '$1 target="_blank" rel="noopener noreferrer"');


    // newline replace
    let newlineRegex = /(<br>)/gi
    if (this.promptRequest?.meta["easy_language"]) {
      sanitizedHtml = sanitizedHtml.replace(newlineRegex, "<br><br>")
    }

    return sanitizedHtml;
  }

  private sortChatHistory(chatHistory: ChatMessage[]) : ChatMessage[] {
    // put all the chunks of one message into the same message object for easier display logic
    // this is necessary because with streaming, ever chunk that is sent looks like a separate answer.
    // delete the chunks from the chat history after they have been appended to the previous chat message
    // once the entire Chat message has been in sent in chunks, it is parsed into formatted html.

    let indexLastConfirmedChat = chatHistory.length > 1 ? chatHistory.length - 2 : 0;
    let lastChat = chatHistory[indexLastConfirmedChat];
    let indexLatestChunk = chatHistory.length > 0 ? chatHistory.length - 1 : 0;
    let latestChunk: ChatMessage = chatHistory[indexLatestChunk];

    if (lastChat.role == latestChunk.role && !latestChunk.meta['is_completed']){
      // this is a chunk that is part of the last chat message
      this.responseCache += latestChunk.content
      lastChat.content= this.parseResponse(this.responseCache)
      // delete the chunk from the history
      chatHistory.pop()
    }
    else if (lastChat.role == latestChunk.role && latestChunk.meta['is_completed'] && lastChat.meta != latestChunk.meta) {
      // this happens when the pipeline sends the last message with the completed answer and the document references -> we need to extract the document references
      lastChat.meta = latestChunk.meta
      lastChat.content = this.parseResponse(latestChunk.content)
      this.responseCache = ""
      chatHistory.pop()
    }
    else if (lastChat.role != latestChunk.role || latestChunk.content == "pong") {
      // this is a new Chat, it does not have to be deleted or altered
      // console.log("this is a new chat or a pong")
    }
    return chatHistory
  }



  get expandedClass(): string {
    return this.expanded ? "expanded" : ""
  }

  toggleExpand() {
    this.expanded = !this.expanded
  }

}
