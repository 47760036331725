<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-FW11E13B9G"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-FW11E13B9G');
</script>


<!-- Toolbar -->
<div class="toolbar" role="banner">
  <div class="toolbar-centred">
    <a href="/#">
      <img
        width="40"
        alt="Logo"
        [src]="iconSource"
        i18n-alt
      />
    </a>
    <a href="/#">
      <h1 class="toolbar-title" [innerHTML]=title></h1>
    </a>

    <ul class="navbar-nav">
      <ng-container>
        <div class="lang-switch-container">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon [ngStyle]="{'color':'black'}">language</mat-icon>
            <mat-icon [ngStyle]="{'color':'black'}">arrow_drop_down</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu" xPosition="before">
          <a mat-menu-item (click)="changeLang(Language.German)" href="/de">Deutsch</a>
          <a mat-menu-item (click)="changeLang(Language.English)" href="/en">English</a>
          <a mat-menu-item (click)="changeLang(Language.French)" href="/fr">Français</a>
        </mat-menu>
      </ng-container>
    </ul>

  </div>
</div>


<div class="content-padding">


  <answer-display [rating] ='rating' [setRating] = "setRating"></answer-display>

  <fine-print class="flex-grow"></fine-print>

</div>

<app-footer></app-footer>

<router-outlet></router-outlet>
