
<div class="suggested-queries" *ngIf="chatHistory.length == 1">
  <button mat-raised-button *ngFor="let element of suggestedQueries" (click)="onSuggestionClick(element)">{{element}}</button>
</div>

<feedback [rating]="rating" [setRating]="setRating"></feedback>

<div class="new-question" *ngIf="chatHistory.length > 1">
  <a mat-raised-button href="/">
    <mat-icon>restart_alt</mat-icon>
    {{newQuestionButtonText}}
  </a>
</div>


<div class="input-field-container horizontal-center">

  <div class="input-field-and-settings">
    <mat-form-field class="input-form-field" color="accent" appearance="outline">
      <mat-label i18n>Stelle mir eine Frage</mat-label>
      <div class="input-with-buttons">
        <textarea matInput
                  cdkTextareaAutosize
                  autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"
                  [(ngModel)]="userQuestion"
                  autofocus
                  autocomplete="off"
                  (keydown.enter)="onEnterKey($event)"
        >
        </textarea>
        <div class="button-container">
          <button *ngIf="userQuestion" class="hide-in-mobile" mat-icon-button aria-label="Clear" (click)="resetQuestion()">
            <mat-icon>close</mat-icon>
          </button>
        <button
          class="hide-in-mobile"
          mat-icon-button
          aria-label="Easy Language"
          matTooltip="Einfache Sprache"
          (click)="changeSimpleAnswer()"
          [ngClass]="{'shadow-enabled': easyLanguage}">
          <mat-icon>accessibility_new</mat-icon>
        </button>
          <button *ngIf="userQuestion" mat-icon-button (click)="makeServerCall()"
                  [disabled]="displayProgressBar && !allowParallelRequests">
            <mat-icon color="accent">send</mat-icon>
          </button>
          <button mat-icon-button (click)="toggleSettings()" matTooltip="Mehr Optionen">
            <mat-icon>tune</mat-icon>
          </button>
        </div>
      </div>
      </mat-form-field>
      <div *ngIf="showSettings" class="settings-panel">
        <div class="gpt-version-buttons">
          <mat-button-toggle-group [(ngModel)]="chosenGPTVersion">
            <!--            <mat-button-toggle [value]=gptVersion.GPT4 aria-label="GPT4">
                          GPT4
                        </mat-button-toggle>-->
            <mat-button-toggle [value]=gptVersion.GPT4o aria-label="GPT4o">
              GPT4o
            </mat-button-toggle>
            <mat-button-toggle [value]=gptVersion.GPT4Turbo aria-label="GPT4">
              GPT4 Turbo
            </mat-button-toggle>
            <mat-button-toggle [value]=gptVersion.GPT4 aria-label="GPT4">
              GPT4
            </mat-button-toggle>
            <mat-button-toggle [value]=gptVersion.GPT3point5turbo aria-label="GPT3.5Turbo">
              GPT3.5 Turbo
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="toggle-container">
        <mat-slide-toggle class="slide-toggle"[(ngModel)]="searchOnly" (click)="$event.stopPropagation()" i18n>Suchmodus (ohne
          GPT Antwort)
        </mat-slide-toggle>
        <mat-slide-toggle class="slide-toggle"[(ngModel)]="easyLanguage" (click)="$event.stopPropagation()" i18n>Antworte in einfacher Sprache
        </mat-slide-toggle>
        <mat-slide-toggle class="slide-toggle"[(ngModel)]="recentnessRankingRequested" (click)="$event.stopPropagation()" i18n>
          Neuere Quellen bevorzugen
        </mat-slide-toggle>
        <mat-form-field id="recentnessRankingToggleArea" *ngIf="recentnessRankingRequested && !environment.production">
          <mat-label id="recentnessRankingNumberLabel">Anzahl zu berücksichtigende Dokumente</mat-label>
          <input id="recentnessRankingNumberInput" type="number" step="1" min="1" max="500" matInput [(ngModel)]="top_k_for_retrieval">
        </mat-form-field>
      </div>
      <source-entity-filter  (checkboxChange)="onCheckboxChange($event)" [items]="suggestedEntityFilters"></source-entity-filter>
    </div>
    </div>
  </div>
