import {Component, computed, effect, Input} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from '@angular/material/snack-bar';
import {QuestionService} from "../../services/question.service";
import {UserPromptResponse} from "../../interfaces/user-prompt-response";
import {CaptureContext, ScopeContext} from "@sentry/types/types/scope";
import {ChatMessage} from "../../interfaces/chat_message";


@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  showAccordion = computed(() => !this.questionService.displayProgressBar() && this.questionService.chatHistorySignal().length > 1);
  answerHasBeenRated: boolean = false;
  extendedFeedbackWasGiven : boolean = false;


  @Input() rating!: { value: number };
  @Input() setRating!: (newRating: number) => void;

  email: any;
  feedback: any;
  dropdown: any;
  currentEventId: string = ''
  lastServerRequestUuid = ''
  chatHistory = this.questionService.chatHistorySignal()
  uuid = this.chatHistory.length > 1 ? this.chatHistory[-1].meta["server_request_uuid"] : "";


  feedbackForm: FormGroup; // Define the form

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private questionService: QuestionService,
  ) {
    this.questionService.newQuestion.subscribe(() =>{
      this.resetFeedbackStatus()
    })
    this.feedbackForm = this.fb.group({
      email: ['', [Validators.email]], //optional
      dropdown: [''], // optional
      feedback: ['']// optional
    });

  }

  resetFeedbackStatus(){
    this.answerHasBeenRated = false;
    this.extendedFeedbackWasGiven = false;
    this.lastServerRequestUuid = '';
    this.currentEventId = '';
    this.email = '';
    this.feedback = '';
    this.dropdown = '';
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  callSetRating(newRating: number): void {
    this.setRating(newRating)
  }

  sendShortFeedback(rating: number) {
    if (!this.answerHasBeenRated){
      this.callSetRating(rating)
      this.sendFeedback()
    }
    else{
      this.openSnackBar($localize`Für diese Antwort hast du bereits eine Rückmeldung abgegeben.`);
    }
  }

  getCurrentDateTime(): string {
    const now = new Date();

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = now.getFullYear().toString().substr(-2);
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;
  }

  sendFeedback() {

    try{
      const serverRequestUuid = this.uuid ? this.uuid : '';

      let modifiedChatHistory = this.modifyChatHistoryForSentry();

      let context : CaptureContext = {
          extra:
            {
              "rating": this.rating.value,
              "Chat History": modifiedChatHistory,
              "server_request_uuid" : serverRequestUuid,
              },
          tags: {
            "rating": this.rating.value
          }
        }
        const message = "User Rating"
        console.log("capture event; ", message)

        this.currentEventId = Sentry.captureMessage(message, context)

      this.lastServerRequestUuid = serverRequestUuid
      this.answerHasBeenRated = true;

      let popUpMessage: string = $localize`Vielen Dank für Deine Rückmeldung!`
      popUpMessage = this.rating.value === 0 ? popUpMessage + $localize` Willst Du mir noch mehr Feedback geben? ` : popUpMessage
      this.openSnackBar(popUpMessage)

    } catch (error){
      this.openSnackBar($localize`Ups, es ist etwas schief gelaufen. Bitte versuche es später noch einmal.`)
    }

  }


  sendExtendedFeedback() {

    this.email = this.feedbackForm.value.email;
    this.dropdown = this.feedbackForm.value.dropdown;
    this.feedback = this.feedbackForm.value.feedback;

    try{
      const serverRequestUuid = this.uuid ? this.uuid : '';

      let context: CaptureContext = {
        extra:
          {
            "eventID": serverRequestUuid,
            "rating": this.rating.value.toString(),
            "Chat History": this.modifyChatHistoryForSentry(),
            "feedback_category": this.dropdown,
            "comment": this.feedback,
            "email": this.email
          },
        tags: {
          "rating": this.rating.value,
          "feedback_category": this.dropdown,
        }
      }
      let sentiment = "Extended User Feedback";
      Sentry.captureMessage(sentiment, context);
      this.extendedFeedbackWasGiven = true;
      this.openSnackBar($localize`Vielen Dank für deine erweiterte Rückmeldung!`);
    }

    catch (error){
      this.openSnackBar($localize`Ups, es ist etwas schief gelaufen. Bitte versuche es später noch einmal.`);
    }

  }


  private modifyChatHistoryForSentry() {
    // meta-objects should be dictionaries, such that they are readable when sent to sentry for feedback

    let modifiedChatHistory: ChatMessage[] = []
    for (let chat of this.chatHistory) {
      let modifiedChat = JSON.parse(JSON.stringify(chat)); //deepcopy
      modifiedChat.meta = JSON.stringify(chat.meta)
      modifiedChatHistory.push(modifiedChat);
    }

    let size = new Blob([JSON.stringify(modifiedChatHistory)]).size
    if (size > 16000) {
      console.warn("Chat History size exceeds Sentry size limit for extra data item. ")
    }

    return modifiedChatHistory;
  }

}
