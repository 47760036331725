<div class="feedback-container">

  <div class="feedback-accordion" *ngIf="showAccordion()">
    <strong i18n> War diese Antwort hilfreich?</strong>

    <div class="rating-area">
            <span class="rating-icon" (click)="sendShortFeedback(1)">
              <mat-icon class="rating-success" [class.selected]="rating.value === 1">thumb_up</mat-icon>
            </span>
            <span class="rating-icon" (click)="sendShortFeedback(0)">
              <mat-icon class="rating-failure" [class.selected]="rating.value === 0">thumb_down</mat-icon>
            </span>
    </div>

    <div class="feedback-form-container" *ngIf="!extendedFeedbackWasGiven">
      <form class="feedback-form" [formGroup]="feedbackForm" (ngSubmit)="sendExtendedFeedback()">
        <ng-container *ngIf="rating.value === 0">

          <mat-form-field>
            <mat-label i18n>Weshalb sind Sie mit der Antwort unzufrieden?</mat-label>
            <mat-select placeholder="" formControlName="dropdown">
              <mat-option value="wrong" i18n>Die Anwort war falsch.</mat-option>
              <mat-option value="unhelpful" i18n>Die Antwort war nicht hilfreich.</mat-option>
              <mat-option value="inappropriate" i18n>Die Antwort war unangebracht.</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="email" placeholder="Emailadresse für allfällige Rückfragen (optional)" i18n-placeholder formControlName="email">
            <mat-error *ngIf="feedbackForm.get('email')?.hasError('invalid')"></mat-error>
          </mat-form-field>

          <mat-form-field >
            <input matInput type="text" placeholder="Ihre Rückmeldung" i18n-placeholder formControlName="feedback">
          </mat-form-field>
          <br/>
          <button type="submit" mat-raised-button color="primary" i18n>Rückmeldung senden</button>
        </ng-container>
      </form>
    </div>
  </div>
</div>
