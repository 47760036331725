<div class="chat-container">

    <div class="chat">
      <div class="chat-section">
        <div class="chat-iterator" *ngFor="let chat of chatHistory">
         <div *ngIf="chat.role == ChatRole.USER" class="received-question-container">
            <div class="chat-message" [innerHTML]="chat.content"></div>

         </div>

         <div *ngIf="chat.role != ChatRole.USER" class="received-answer-container">
           <div class="icon-container">
              <img
                class="icon"
                width="40"
                alt="SHAU Logo"
                [src]="iconSource"
                i18n-alt
              />
           </div>
          <div class="chat-message" [innerHTML]="chat.content"></div>
         </div>
        </div>
       <div *ngIf="displayProgressBar" class="received-answer-container">
         <div class="icon-container">
            <img
              class="icon"
              width="40"
              alt="SHAU Logo"
              [src]="iconSource"
              i18n-alt
            />
         </div>
            <div class="loader-container">
                 <div class="loader"></div>
            </div>
       </div>
      </div>
    <question-input [rating] ='rating' [setRating] = "setRating" ></question-input>
    <privacy-hint-hallucination-disclaimer class="before-animation"></privacy-hint-hallucination-disclaimer>
  </div>

  <div *ngIf="latestResponse.content && !displayProgressBar && isCompleted" class="received-sources-container">
    <mat-table class="received-sources" [dataSource]="latestResponseDocumentReferences">

      <ng-container matColumnDef="entry">
        <mat-header-cell *matHeaderCellDef class="sources-header-cell">
          <p class="mat-source-header" >{{sourcesTableHeader}}</p>
          <button mat-icon-button (click)="toggleExpand()">
            <mat-icon>
              <ng-container *ngIf="expanded">
                unfold_more
              </ng-container>
              <ng-container *ngIf="!expanded">
                unfold_less
              </ng-container>
            </mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" >
          <div class="document-details">
            <h3 [matTooltip]=element.title_to_display class="filename"><a href="{{element.link}}"  target="_blank">{{ element.title_to_display}}</a></h3>
            <p class="meta-info" i18n>
              <a href="{{ element.link }}" target="_blank">{{ element.link }}</a>
              <br>
              <span *ngIf="element.author">Autor/in: {{ element.author }} </span>
              <ng-container *ngIf="element.page_number && element.page_number > 1" >Seite: {{element.page_number}}</ng-container>
              <ng-container *ngIf="element.source_entity && element.source_entity != ''" ><br>Quelle: {{element.source_entity}}</ng-container>
            </p>

            <br>
            <p class="preview">
              <span [class]="expandedClass">
                <span *ngIf="element.date"><b>{{ element.date }}</b> – </span>{{ element.preview }}
              </span>
            </p>
          </div>
        </mat-cell>
        <mat-footer-cell></mat-footer-cell>

      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <ng-container matColumnDef="noRecord">
          <mat-footer-cell  mat-footer-cell *matFooterCellDef i18n>
            Keine passenden Quellen gefunden.
          </mat-footer-cell>
      </ng-container>

      <ng-template [ngIf]="latestResponseDocumentReferences.length === 0 && isCompleted">
        <mat-footer-row *matFooterRowDef="['noRecord']" class="mat-footer" ></mat-footer-row>
      </ng-template>

    </mat-table>

    </div>
  </div>


