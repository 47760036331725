import {Component, computed, effect, Input, OnInit, Signal, signal, WritableSignal} from '@angular/core';
import {QuestionService} from "../../services/question.service";
import {ActivatedRoute} from "@angular/router";
import {v4 as uuidv4} from "uuid";
import {Language} from "../../interfaces/languages";
import {Location} from '@angular/common';
import {gptVersion} from "../../interfaces/gptVersions";
import {environment} from 'src/environments/environment';
import {WhitelabelService} from "../../services/whitelabel.service";
import {ChatMessage} from "../../interfaces/chat_message";
import {ChatRole} from "../../interfaces/ChatRole";
import {UserPromptRequestMeta} from "../../interfaces/user-prompt-request-meta";


@Component({
  selector: 'question-input',
  templateUrl: './question-input.component.html',
  styleUrls: ['./question-input.component.scss']
})
export class QuestionInputComponent implements OnInit {

  allowParallelRequests = false

  userQuestion = '';
  showSettings = false;
  displayProgressBar = false;
  chatHistory: ChatMessage[] = []
  searchOnly = false;
  easyLanguage = false;
  chosenGPTVersion = this.whitelabelService.defaultGPTVersion
  recentnessRankingRequested = this.whitelabelService.recentnessRankingRequestedByDefault;
  sourceEntityFilter: string[] = [];
  top_k_for_retrieval: number = 100;

  suggestedEntityFilters: string[] = this.whitelabelService.sources
  newQuestionButtonText : string = this.whitelabelService.newQuestion

  suggestedQueries: string[] = this.whitelabelService.suggestedQueries;

  environment = environment


  protected readonly gptVersion = gptVersion;
  @Input() setRating!: (newRating: number) => void;
  @Input() rating!: { value: number };

  callResetRating() {
    this.setRating(-1)
  }

  constructor(
    private questionService :  QuestionService,
    private route: ActivatedRoute,
    private location: Location,
    private whitelabelService: WhitelabelService,
  ) {
    effect(() => { //update signals from questionService when their values change
      this.displayProgressBar = this.questionService.displayProgressBar();
      this.chatHistory = this.questionService.chatHistorySignal();

    })
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const allowParallelRequestsString = params['allowParallelRequests']
      if (allowParallelRequestsString != null) {
        this.allowParallelRequests = allowParallelRequestsString.toLowerCase() == 'true';
      }

      const question = params['q']
      if (question){
        this.userQuestion = decodeURI(question)
        if (this.userQuestion.length > 0) {
          this.makeServerCall().catch(e => {
            console.error(e)
          })
        }
      }
    })
  }

  onSuggestionClick(suggestion: string){

    if (this.displayProgressBar) {
      return
    }

    this.userQuestion = suggestion;
    this.makeServerCall().catch(e => {
        console.error(e)
      }
    )
  }

  toggleSettings(){
    this.showSettings = !this.showSettings;
  }

  onEnterKey(event: Event): void {
    event.preventDefault()
    if (this.userQuestion.length > 0) {
      this.makeServerCall().catch(e => {
          console.error(e)
        }
      )
    }
  }

  async makeServerCall(){
    this.location.replaceState('?q='+ encodeURI(this.userQuestion));

    if (this.displayProgressBar && !this.allowParallelRequests) {
      // prevent multiple calls
      return
    }
    this.callResetRating()


    const getKeyByValue = (value: string): string | undefined =>
      (Object.keys(Language) as Array<keyof typeof Language>).find(key => Language[key] === value);

    const userLanguageAsString : string = localStorage.getItem("userLanguage") ||'de';
    const keyByValueString = getKeyByValue(userLanguageAsString)
    const userLanguage: Language |undefined = keyByValueString ? Language[keyByValueString as keyof typeof Language] :  Language.German;


    const promptMeta: UserPromptRequestMeta = {
      client_request_uuid: uuidv4(),
      search_only: this.searchOnly,
      language : userLanguage,
      gpt_version : this.chosenGPTVersion,
      easy_language: this.easyLanguage,
      recentness_ranking_requested: this.recentnessRankingRequested,
      source_entity_filter: this.sourceEntityFilter,
      top_k_for_retrieval: this.top_k_for_retrieval
    }
    let newPrompt: ChatMessage ={
      content: this.userQuestion,
      role: ChatRole.USER,
      meta: promptMeta
    }
    console.log(prompt)
    this.resetQuestion()
    await this.questionService.makeServerCall(newPrompt);
  }

  resetQuestion() {
    this.userQuestion = ''
  }

  onCheckboxChange(checkedItems: string[]) {
    // Process checked items here
    console.log(checkedItems);
    this.sourceEntityFilter = checkedItems
  }

  changeSimpleAnswer() {
    this.easyLanguage = !this.easyLanguage
  }
}
